import React from "react";
import { Hero } from "../../components/component";
import Faq from "./faq";
import Invest from "./invest";
import Support from "./support";
import EcoSystem from "./ecoSystem";
import HowItWorks from "./HowItWorks";
import Premium from "./premium";
import ContactUs from "./contactUs";
import PreferToSay from "../../components/PreferToSay";
import Questions from "./questions";
import MajorFeatures from "../../components/features/majorFeatures";
import Integrations from "./HowItWorks";
import Testimonoals from "./testimonials";

const Home_1 = () => {
  return (
    <main className="overflow-hidden">
      <Hero />
      <EcoSystem />
      <HowItWorks />
      {/* <Integrations/> */}
      <MajorFeatures />
      {/* <Premium /> */}
      <Faq />
      <Support />
      <Testimonoals />
      <Invest />
      <ContactUs />
      <PreferToSay />
      <Questions />
    </main>
  );
};

export default Home_1;
